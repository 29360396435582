import welcomeBonusBanner from './assets/2.png';
import cashbackBonusBanner from './assets/1.png';
import referWinBonusBanner from './assets/5.png';
import appBonusBanner from './assets/4.png';
import dailyLoginBanner from './assets/daily-login-bonus.jpeg';

type BonusItem = {
  id: string;
  title: string;
  description: string;
  preview: string;
  content: string;
};

export const bonuses: BonusItem[] = [
  {
    id: 'welcome-bonus',
    title: 'Welcome Bonus',
    description: 'Metabet offers the Welcome Bonus to reward new customers by matching their first deposit with a bonus equal to the deposit amount upto Kes 5,000.',
    preview: welcomeBonusBanner,
    content: `
      <h1>Welcome Bonus</h1>
      <p><img src=${welcomeBonusBanner} /></p>
      <p>Metabet offers the Welcome Bonus to reward new customers by matching their first
          deposit with a bonus equal to the deposit amount upto Kes 5,000.
        </p>
        <p><b>Rules:</b></p>
        <ul>
          <li>1. The customer receives a bonus equal to 100% of their initial deposit upto Kes 5,000.</li>
          <li>2. The bonus is only applicable to sportsbook. </li>
          <li>3. The minimum odds to stake with the bonus is 9.99 with a maximum of 100 odds</li>
          <li>4. Note that the games played should be pre-games. They should be be a            multibet/express for the bonus to qualify.</li>
          <li>5. Live games and cash-outs does not apply on the bonus.</li>
          <li>6. The maximum amount that the bonus applies is Kes 5,000</li>
        </ul>
        <p><b>*Customer gets 100% Welcome Bonus on deposits up to Kes 5,000</b></p>
    `,
  },
  {
    id: 'cashback-bonus',
    title: 'CASH BACK BONUS',
    description: 'Metabet offers a 10% Cash Back to reward customers when they lose their bets.',
    preview: cashbackBonusBanner,
    content: `
      <h1>CASH BACK BONUS</h1>
      <p><img src=${cashbackBonusBanner} /></p>
      <h2>Stay in the game, Be among the champions!</h2>
        <p>Metabet offers a 10% Cash Back to reward customers when they lose their bets.</p>
        <p><b>Rules:</b></p>
        <ul>
          <li>A bonus equal to 10% of the total stake of your lost bets is credited every Tuesday at
            12:00 EAT. The bonus is awarded to customers automatically.
          </li>
          <li>To wager your bonus, you must place an accumulator bet of at least Kes 5,000 Weekly.</li>
          <li>Players will get a 10% Cash back.</li>
          <li>Losing bets must be settled at odds of 1.5 or higher.</li>
          <li>Bets on totals and handicaps will not be taken into account when calculating the
            value of the bonus.
          </li>
          <li>Bets placed using promo codes and bonus funds, bets settled at odds of 1.00,
            canceled bets and bets that have been cashed out are not included in this offer.
          </li>
          <li>This offer also doesn't apply to bets that haven't been settled by 23:59 EAT on the
            Monday before the Tuesday when cashback is credited.
          </li>
          <li>You can only have 1 active bonus, which means that when the cashback is calculated
            (at 12:00 EAT on Tuesday), you must not have any other bonus funds in your account.
          </li>
          <li>Metabet reserves the right to review customers' transaction records and logs for any
            reason. If, upon such review, it appears that a customer is participating in strategies
            that the company, at its sole discretion, deems to be abusive, the company reserves
            the right to revoke the customer's entitlement to participate in the bonus program
            and void their bonus.
          </li>
          <li>Only one bonus is allowed per customer, family, address, shared computer, shared IP
            address, and any identical account details including e-mail address, bank account
            details, credit card information and payment system account. Any misuse of this
            bonus offer will lead to an account being closed.
          </li>
          <li>The customer must provide identification documents, if necessary, to verify their
            identity (KYC). Failure to produce these documents at the request of the Company
            will result in the forfeiture of the bonus and any winnings. The Company has the
            right to request that a customer verify their identity over the phone or provide a
            photo of themselves holding their ID document
            (the customer's face must be clearly visible) at any time.
            All types of bonus are disabled for cryptocurrencies, without exception.
          </li>
        </ul>
        <p><b>Metabet has the right to amend the terms of the offer, cancel or renew the offer, or refuse participation at any time without prior notice.</b></p>
    `,
  },
  {
    id: 'refer-win-bonus',
    title: 'REFER & WIN',
    description: 'Refer friends to Metabet, & get up to 10% commission each time they play.',
    preview: referWinBonusBanner,
    content: `
      <h1>REFER & WIN</h1>
      <p><img src=${referWinBonusBanner} /></p>
      <p>Refer friends to Metabet, & get up to 10% commission each time they play.</p>
    `,
  },
  {
    id: 'app-bonus',
    title: 'APP BONUS',
    description: 'Download MetaApp and get Kes 10. Play Now, win big.',
    preview: appBonusBanner,
    content: `
      <h1>REFER & WIN</h1>
      <p><img src=${appBonusBanner} /></p>
      <p>Download MetaApp and get Kes 10. Play Now, win big.</p>
    `,
  },
  {
    id: 'daily-login-bonus',
    title: 'DAILY LOGIN BONUS',
    description: 'Daily login bonus on Sports! Play to win!',
    preview: dailyLoginBanner,
    content: `
      <h1>DAILY LOGIN BONUS</h1>
      <p><img src=${dailyLoginBanner} /></p>
      <ul>
        <li><b>1. General</b>
          <ul>
            <li>1.1. <b>Daily login bonus</b> ("Promotion") is organized by <b>Metabet.ke</b>. </li>
            <li>1.2. By participating in this promotion, players agree to abide by these Terms & Conditions.</li>
            <li>1.3. The promotion runs everyday <b>(local time)</b>.</li>
          </ul>
        </li>
        <li><b>2. Eligibility</b>
          <ul>
            <li>2.1. The promotion is open to all registered <b>Metabet.ke</b> players aged <b>18 years and above</b>. </li>
            <li>2.2. Only verified accounts with a valid phone number.</li>
          </ul>
        </li>
        <li><b>3. Promotion Details </b>
          <ul>
            <li>3.1. The daily <b>login bonus</b> offers players a <b>bonus ranging from KES 10 to KES 10,000</b> during the promotional period.</li>
            <li>3.2. To claim the <b>daily login Bonus</b>, players must use the promo code indicated on the site.</li>
            <li>3.3. <b>The bonus is credited instantly</b> after the promo code is applied.</li>
            <li>3.4. After claiming the bonus, the punter must make a deposit <b>equal to the awarded bonus</b> before placing bets.</li>
            <li>3.5. <b>The maximum winnings from the bonus are capped at KES 20,000.</b> Any amount exceeding this limit will not be credited.</li>
          </ul>
        </li>
        <li><b>4. Bonus Wagering Requirements</b>
          <ul>
            <li>4.1. The awarded bonus must be placed in full on a multi-bet of at least 5games with cumulative odds of 9.99 or higher.</li>
            <li>4.2. Each selection within the multi-bet must have minimum odds of 1.5 or more.</li>
            <li>4.3. Immediately after claiming the bonus, the punter must deposit the amount equal to the awarded bonus and place a bet with minimum odds of 2.00 with the deposit, before using the bonus claimed.</li>
            <li>4.4. If a punter places bets using only the bonus without making the required deposit, the bet will go through. However, any winnings will remain in the bonus account. </li>
            <li>4.5. If a punter divides the bonus and places multiple bets, the winnings will count but will remain in the bonus account.</li>
            <li>4.6. The new winnings become the new baseline, and the punter must deposit an amount equal to the new winnings to unlock them. </li>
            <li>4.7. A deposit made after winning will NOT count toward unlocking the winnings. The required deposit must be made immediately after claiming the bonus. </li>
            <li>4.8. The initial wagering requirements remain in effect, even after winnings are generated from divided bonus bets. </li>
            <li>4.9. Any misuse of the bonus (bonus arbitrage) especially on two-way markets will forfeit the winnings immediately. </li>
          </ul>
        </li>
        <li><b>5. Deposit Winnings & Withdrawals</b>
          <ul>
            <li>5.1. <b>Deposit winnings are NOT tied to the bonus and can be withdrawn instantly.</b></li>
            <li>5.2. Any winnings from the bonus will be credited to the player's main account after meeting all wagering requirements. </li>
          </ul>
        </li>
        <li><b>6. Fraud & Abuse</b>
          <ul>
            <li>6.1. <b>Metabet.ke</b> reserves the right to cancel or revoke bonuses in case of suspected fraudulent activities, multiple accounts, or abuse of the promotion. </li>
            <li>6.2. Accounts found in violation of these terms will be suspended or permanently banned.</li>
          </ul>
        </li>
        <li><b>7. Amendments & Termination</b>
          <ul>
            <li>7.1. <b>Metabet.ke</b> reserves the right to modify, suspend, or terminate the promotion at any time without prior notice. </li>
            <li>7.2. Any changes will be communicated via the official website and social media channels.</li>
          </ul>
        </li>
      </ul>
    `,
  },
];

export const bonusesUA: BonusItem[] = [];

export const bonusesEN: BonusItem[] = [];

export const bonusessListByLang: Record<string, BonusItem[]> = {
  default: bonuses,
  'en': bonuses,
};
