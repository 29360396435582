export const rulesList = [
  {
    id: 'terms',
    title: 'TERMS AND CONDITIONS',
    content: `
      <h1>TERMS AND CONDITIONS</h1>

    `,
  },

  {
    id: 'age-res',
    title: 'Age restrictions<',
    content: `
      <h1>Age restrictions</h1>
        <p>
          You must be 18 or over and meet certain other eligibility criteria and
          requirements to register for an Account and use the Services. It is also
          important that you determine whether your use of the Services is illegal in your
          home jurisdiction or (if different) your current location, and you may not use
          the Services if this is the case.
        </p>
    `,
  },

  {
    id: 'privacy-policy',
    title: 'Privacy and Cookies',
    content: `
      <h2>
          Privacy and Cookies
        </h2>
        <ul>
          <li>
            1. Your personal information is processed in accordance with our Privacy Policy, a
            copy of which is available on our website.
          </li>
          <li>
            2. Our Cookies Policy – a copy of which is available on our website – explains what
            cookies are, how they are used on the Services, and how to manage their use.
          </li>
        </ul>
    `,
  },

  // {
  //   id: 'rule',
  //   title: '',
  //   content: `
  //     <h2></h2>
  //     <ul>
  //       <li></li>
  //     </ul>
  //   `,
  // },
];
